<template>
  <v-progress-circular
    class="mx-1"
    size="20"
    width="3"
    indeterminate
  />
</template>

<script>
import { mapMutations, mapActions } from 'vuex'
import { INFO_ICON } from '../../../constants/app'
import { PENDING_REQUEST_CHECKING_INTERVAL } from '../../../constants/pendingRequests'

export default {
  props: {
    request: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    infoIcon: INFO_ICON,
    interval: null
  }),
  mounted () {
    clearInterval(this.interval)

    this.interval = setInterval(() => {
      if (new Date() >= new Date(this.request.expiration)) {
        this.removeRequest(this.request.id)
        clearInterval(this.interval)
      } else {
        this.checkRequestStatus({
          request: this.request,
          interval: this.interval
        })
      }
    }, PENDING_REQUEST_CHECKING_INTERVAL)
  },
  methods: {
    ...mapMutations('pendingRequests', ['removeRequest']),
    ...mapActions('pendingRequests', ['checkRequestStatus'])
  }
}
</script>
